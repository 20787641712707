import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { lazy, Suspense, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ScrollToTop from "./adminpanel/components/commonComponent/scrollToTop";
import ProtectedRoute from "./adminpanel/components/commonComponent/protectedRoutes";

const PaymentSuccess = lazy(() =>
  import("./adminpanel/components/paymentDone")
);
const PaymentFailure = lazy(() =>
  import("./adminpanel/components/paymentFail")
);
const SocialMedia = lazy(() =>
  import("./adminpanel/components/socialMang/socialMedia")
);
const AddSocailMedia = lazy(() =>
  import("./adminpanel/components/socialMang/addSocialMedia")
);
const SocialMediaDetails = lazy(() =>
  import("./adminpanel/components/socialMang/socialMediaDetails")
);
const AddSponser = lazy(() =>
  import("./adminpanel/components/merchantMang/addSponser")
);
const Notifications = lazy(() =>
  import("./adminpanel/components/commonComponent/notifications")
);
const OfferDetailListing = lazy(() =>
  import("./adminpanel/components/dealMang/offerDetailListing")
);
const TContentDetails = lazy(() =>
  import("./adminpanel/components/contentMang/trendingContentDetails")
);
const RedflagDetailsMerchant = lazy(() =>
  import("./adminpanel/components/redFlagMang/redflagDetailsMerchant")
);
const AddMaintenance = lazy(() =>
  import("./adminpanel/components/maintenanceMang/addMaintenance")
);
const MerchantTransactionDetails = lazy(() =>
  import("./adminpanel/components/transactionMang/merchantTransactionDetails")
);
const TrendingContent = lazy(() =>
  import("./adminpanel/components/contentMang/trendingContent")
);
const EditContent = lazy(() =>
  import("./adminpanel/components/contentMang/editDetails")
);
const Content = lazy(() =>
  import("./adminpanel/components/contentMang/content")
);
const ContentDetails = lazy(() =>
  import("./adminpanel/components/contentMang/contentDetails")
);
const AddContent = lazy(() =>
  import("./adminpanel/components/contentMang/addContent")
);
const Help = lazy(() => import("./adminpanel/components/helpMang/help"));
const HelpDetails = lazy(() =>
  import("./adminpanel/components/helpMang/helpDetails")
);
const Maintenance = lazy(() =>
  import("./adminpanel/components/maintenanceMang/maintenance")
);
const MaintenanceDetails = lazy(() =>
  import("./adminpanel/components/maintenanceMang/maintenanceDetails")
);
const Transaction = lazy(() =>
  import("./adminpanel/components/transactionMang/transaction")
);
const TransactionDetails = lazy(() =>
  import("./adminpanel/components/transactionMang/transactionDetails")
);
const RedFlag = lazy(() =>
  import("./adminpanel/components/redFlagMang/redflag")
);
const RedflagDetails = lazy(() =>
  import("./adminpanel/components/redFlagMang/redflagDetails")
);
const Profile = lazy(() => import("./adminpanel/components/profile"));
const ChangePassword = lazy(() =>
  import("./adminpanel/components/changePassword")
);

const MerchantDetails = lazy(() =>
  import("./adminpanel/components/merchantMang/merchantDetails")
);
const AddMerchant = lazy(() =>
  import("./adminpanel/components/merchantMang/addMerchant")
);
const Merchant = lazy(() =>
  import("./adminpanel/components/merchantMang/merchant")
);
const Subscription = lazy(() =>
  import("./adminpanel/components/subscriptionMang/subscription")
);
const AddSubscription = lazy(() =>
  import("./adminpanel/components/subscriptionMang/addSubscription")
);
const SubscriptionView = lazy(() =>
  import("./adminpanel/components/subscriptionMang/subscriptionView")
);
const Promo = lazy(() => import("./adminpanel/components/promo"));
const AddPromo = lazy(() => import("./adminpanel/components/addPromo"));
const PromoDetails = lazy(() => import("./adminpanel/components/promoDetails"));
const Deal = lazy(() => import("./adminpanel/components/dealMang/deal"));
const AddDeal = lazy(() => import("./adminpanel/components/dealMang/addDeal"));
const AddCoupon = lazy(() =>
  import("./adminpanel/components/dealMang/addCoupon")
);
const Login = lazy(() => import("./adminpanel/components/login"));
const Dashboard = lazy(() => import("./adminpanel/components/dashboard"));
const UserDetails = lazy(() => import("./adminpanel/components/userDetails"));
const RatingReviewDetails = lazy(() =>
  import("./adminpanel/components/ratingReviewDetails")
);
const Users = lazy(() => import("./adminpanel/components/Users"));
const RatingReview = lazy(() => import("./adminpanel/components/ratingReview"));
const Advertisement = lazy(() =>
  import("./adminpanel/components/advertisement")
);
const AdvertisementDetails = lazy(() =>
  import("./adminpanel/components/advertisementDetails")
);
const EditAdvertisement = lazy(() =>
  import("./adminpanel/components/editAdvertisement")
);
const ForgotPassword = lazy(() =>
  import("./adminpanel/components/forgotPassword")
);
const Verification = lazy(() => import("./adminpanel/components/verification"));
const ResetPassword = lazy(() =>
  import("./adminpanel/components/resetPassword")
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      cacheTime: 24 * 60 * 60 * 1000,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      keepPreviousData: true,
    },
  },
});

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => {
      const online = navigator.onLine;
      setIsOnline(online);

      if (!online) {
        window.location.href = "chrome-error://chromewebdata/";
      }
    };

    updateOnlineStatus();

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  return (
    <>
      {isOnline ? (
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ScrollToTop />
            <Suspense fallback={<div></div>}>
              <Routes>
                <Route path="/admin/login" element={<Login />} />
                <Route
                  path="/admin/forgot-password"
                  element={<ForgotPassword />}
                />
                <Route path="/admin/verification" element={<Verification />} />
                <Route
                  path="/admin/reset-password"
                  element={<ResetPassword />}
                />
                <Route
                  path="/admin/payment-success"
                  element={<PaymentSuccess />}
                />
                <Route
                  path="/admin/payment-failed"
                  element={<PaymentFailure />}
                />
                <Route element={<ProtectedRoute />}>
                  <Route path="/admin/dashboard" element={<Dashboard />} />
                  <Route path="/admin/users-management" element={<Users />} />
                  <Route
                    path="/admin/user-details/:id"
                    element={<UserDetails />}
                  />
                  <Route
                    path="/admin/merchant-management"
                    element={<Merchant />}
                  />
                  <Route
                    path="/admin/merchant-details/:id"
                    element={<MerchantDetails />}
                  />
                  <Route
                    path="/admin/add-sponser/:id"
                    element={<AddSponser />}
                  />
                  <Route path="/admin/add-merchant" element={<AddMerchant />} />
                  <Route
                    path="/admin/rating-and-review-management"
                    element={<RatingReview />}
                  />
                  <Route
                    path="/admin/rating-review-details/:id"
                    element={<RatingReviewDetails />}
                  />

                  <Route
                    path="/admin/advertisement-package"
                    element={<Advertisement />}
                  />
                  <Route
                    path="/admin/advertisement-package-details/:id"
                    element={<AdvertisementDetails />}
                  />
                  <Route
                    path="/admin/edit-advertisement"
                    element={<EditAdvertisement />}
                  />
                  <Route
                    path="/admin/subscription-plan"
                    element={<Subscription />}
                  />
                  <Route
                    path="/admin/add-subscription-plan"
                    element={<AddSubscription />}
                  />
                  <Route
                    path="/admin/subscription-plan-details/:id"
                    element={<SubscriptionView />}
                  />

                  <Route
                    path="/admin/social-media-management"
                    element={<SocialMedia />}
                  />
                  <Route
                    path="/admin/add-social-media"
                    element={<AddSocailMedia />}
                  />
                  <Route
                    path="/admin/social-media-details/:id"
                    element={<SocialMediaDetails />}
                  />

                  <Route path="/admin/promotional-banner" element={<Promo />} />
                  <Route
                    path="/admin/add-promotional-banner"
                    element={<AddPromo />}
                  />
                  <Route
                    path="/admin/promotional-banner-view/:id"
                    element={<PromoDetails />}
                  />

                  <Route path="/admin/deal-management" element={<Deal />} />
                  <Route
                    path="/admin/deal-view-listing/:id"
                    element={<OfferDetailListing />}
                  />
                  <Route path="/admin/add-deal" element={<AddDeal />} />
                  <Route path="/admin/add-coupon" element={<AddCoupon />} />

                  <Route
                    path="/admin/content-management"
                    element={<Content />}
                  />
                  <Route
                    path="/admin/add-trending-content"
                    element={<TrendingContent />}
                  />
                  <Route path="/admin/add-content" element={<AddContent />} />
                  <Route
                    path="/admin/view-trending-content/:id"
                    element={<TContentDetails />}
                  />
                  <Route
                    path="/admin/view-content/:id"
                    element={<ContentDetails />}
                  />
                  <Route
                    path="/admin/edit-content/:id"
                    element={<EditContent />}
                  />
                  <Route path="/admin/help-&-Support" element={<Help />} />
                  <Route
                    path="/admin/view-help-&-Support/:id"
                    element={<HelpDetails />}
                  />
                  <Route
                    path="/admin/maintenance-and-updates"
                    element={<Maintenance />}
                  />
                  <Route
                    path="/admin/view-maintenance-and-updates/:id"
                    element={<MaintenanceDetails />}
                  />
                  <Route
                    path="/admin/add-maintenance-and-updates"
                    element={<AddMaintenance />}
                  />
                  <Route
                    path="/admin/transaction-management"
                    element={<Transaction />}
                  />
                  <Route
                    path="/admin/view-user-transaction/:id"
                    element={<TransactionDetails />}
                  />
                  <Route
                    path="/admin/view-merchant-transaction/:id"
                    element={<MerchantTransactionDetails />}
                  />
                  <Route
                    path="/admin/red-flag-management"
                    element={<RedFlag />}
                  />
                  <Route
                    path="/admin/view-red-flag-management/:id"
                    element={<RedflagDetails />}
                  />
                  <Route
                    path="/admin/view-merchant-red-flag-management/:id"
                    element={<RedflagDetailsMerchant />}
                  />

                  <Route path="/admin/view-profile" element={<Profile />} />
                  <Route
                    path="/admin/change-password"
                    element={<ChangePassword />}
                  />
                  <Route
                    path="/admin/notifications"
                    element={<Notifications />}
                  />
                </Route>

                <Route path="*" element={<Navigate to="/admin/login" />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </QueryClientProvider>
      ) : (
        <div className="offline-container pb-5">
          <img
            src="../assets/img/internet.gif"
            alt="Offline"
            className="offline-image"
          />
          <h1 className="offline-title">You are offline!</h1>
          <p className="offline-message text-center">
            It seems that your internet connection is lost. <br />
            Please check your connection and try reloading the page.
          </p>
        </div>
      )}
    </>
  );
}

export default App;
